<template>
  <div class="about">
    <div class="breadcrumbs" data-aos="fade-in">
      <div class="container">
        <h2>{{$t("documents")}}</h2>
        
      </div>
    </div>

    <section id="why-us" class="events">
      <div class="container" data-aos="fade-up">
         <div class="row">
          <div class="col-lg-12 align-items-stretch">
            <div class="card">
              <div class="card-img">
                <img src="/assets/img/banner1.png">
              </div>
              
            </div>
          </div>
          <div class="row">
            <div class="card mt-5" v-for="(document, d) in documents" :key="`document-${d}`">
                <div class="card-body">
                    <h5 class="card-title">{{document.title[$i18n.locale]}}</h5>
                    <div class="icon-boxes d-flex flex-column justify-content-center">
                        <div class="row">
                            <div class="col-xl-12 d-flex align-items-stretch">
                                <div class="icon-box mt-4 mt-xl-0">
                                    <p><a :href="`/assets/documents/${document.title[$i18n.locale]}.pdf`">{{$t("click-here-to-download-file", {name : document.title[$i18n.locale]})}}</a></p>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

          </div>
        </div>        
      </div>
    </section>

  </div>
</template>
<script>
export default {
  components: { },
  data(){
    return {
      documents : []
    }
  },
  created(){
    this.$http.get(`/innovations/documents`)
    .then(response => {
      this.documents = response.data.message;
    }).catch(e => {
      console.log(e)
    })
  }
  
}
</script>
